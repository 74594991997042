import React, { useEffect } from "react";
import style from "./About.module.scss";
import julia from '../../assets/home/49ed29a171d0b0b551cec7aeb9a9147e.jpg';
import plantes from '../../assets/about/plantes.svg';
import bea from '../../assets/about/bea.svg';
import flower from '../../assets/about/flower.svg';
import cocktail from '../../assets/about/cocktail.svg';
import illuliaLogo from '../../assets/illustration-himself.svg';
import cv from '../../assets/CV_2024_Vacher_Julia.pdf';
import List from "../../components/List/List";
import RoundButton from "../../components/Round/Button/RoundButton";
import StyleHelper from "../../services/Style.helper";
import XLTitle from "../../components/Titles/XLTitle/XLTitle";
import Text from "../../components/Text/Text";
import {useTranslation} from "react-i18next";
import Image from "../../components/Image/Image";

const About = () => {

    const {t} = useTranslation();

    useEffect(() => {
        const handleMouseMove = (e: MouseEvent, element: HTMLElement) => {
            const rect = element.getBoundingClientRect();
            const x = e.clientX - rect.left;
            const y = e.clientY - rect.top;
            
            element.style.setProperty('--mouse-x', `${x}px`);
            element.style.setProperty('--mouse-y', `${y}px`);
        };

        const items = document.querySelectorAll(`.${style.illulia__gallery__item}`);
        
        items.forEach(item => {
            const element = item as HTMLElement;
            element.addEventListener('mousemove', (e) => handleMouseMove(e as MouseEvent, element));
        });

        return () => {
            items.forEach(item => {
                const element = item as HTMLElement;
                element.removeEventListener('mousemove', (e) => handleMouseMove(e as MouseEvent, element));
            });
        };
    }, []);

    const downloadCV = () => {
        window.open(cv, '_blank');
    }

    return (
        <div>
            <div className={StyleHelper.concatClass([
                style.grid,
                style.grid__12,
                style.grid__spacing__1
            ])}>
                <div className={StyleHelper.concatClass([
                    style.col_xl_1_6,
                    style.col_lg_1_6,
                    style.col_md_1_13,
                    style.col_sm_1_13,
                    style.container
                ])}>
                    <XLTitle text={[t('about.title')]} sub={[t('about.subtitle')]}/>
                    <Text classes={style.container__text}
                          text={t('about.texts.1')}
                    />
                    <Text classes={style.container__text}
                          text={t('about.texts.2')}
                    />
                    <Text classes={style.container__text}
                          text={t('about.texts.3')}
                    />
                </div>

                <Image
                    classes={[
                        style.col_xl_7_11,
                        style.col_lg_7_11,
                        style.col_md_3_9,
                        style.col_sm_3_9,
                        style.img,
                    ]}
                    src={julia}
                    alt={t('fullName')}/>

                <div className={StyleHelper.concatClass([
                    style.col_xl_11_13,
                    style.col_lg_11_13,
                    style.col_md_9_13,
                    style.col_sm_10_13,
                    style.container
                ])}>
                    <RoundButton text={'Mon CV'} color={'white'} onClick={downloadCV} classes={[style.btnWrapper]}/>
                </div>

                <div className={StyleHelper.concatClass([
                    style.col_xl_1_6,
                    style.col_lg_1_6,
                    style.col_md_1_13,
                    style.col_sm_1_13,
                    style.skills
                ])}>
                    <List title={'Expertises'}
                          subtitle={'En perpétuelles évolutions'}
                          items={[
                              'Direction artistique',
                              'Branding & Identité',
                              'UI/UX design',
                              'Motion design',
                              'Illustrations'
                          ]}/>
                </div>

                <div className={StyleHelper.concatClass([
                    style.col_xl_8_13,
                    style.col_lg_8_13,
                    style.col_md_1_13,
                    style.col_sm_1_13,
                    style.tools
                ])}>
                    <List title={'Des outils'}
                          subtitle={'Bien aiguisés'}
                          items={[
                              'Figma / Adobe Xd',
                              'Adobe - Illustrator / photoshop / Indesign',
                              'Premier pro / After effect'
                          ]}/>
                </div>

                <div className={StyleHelper.concatClass([
                    style.col_xl_5_9,
                    style.col_lg_5_9,
                    style.col_md_4_10,
                    style.col_sm_1_13,
                ])}>
                    <div className={style.illulia__header}>
                        <img src={illuliaLogo} alt="illu.lia logo" className={style.illulia__header__logo} />
                        <h3 className={style.illulia__header__title}>illu.lia</h3>
                        <h4 className={style.illulia__header__subtitle}>ça aussi c'est moi</h4>
                    </div>
                    
                    <p className={style.illulia__description}>
                        Voici un petit aperçu de mon instagram dédié à mes illustrations personnelles réalisées sur Procreate
                    </p>
                </div>

                {/* Première ligne d'images */}
                <div className={StyleHelper.concatClass([
                    style.col_xl_1_4,
                    style.col_lg_1_4,
                    style.col_md_1_4,
                    style.col_sm_1_7,
                    style.illulia__gallery__item
                ])}>
                    <img src={plantes} alt="Plantes" />
                </div>
                <div className={StyleHelper.concatClass([
                    style.col_xl_4_7,
                    style.col_lg_4_7,
                    style.col_md_4_7,
                    style.col_sm_7_13,
                    style.illulia__gallery__item
                ])}>
                    <img src={cocktail} alt="Cocktail" />
                </div>
                {/* Deuxième ligne d'images */}
                <div className={StyleHelper.concatClass([
                    style.col_xl_7_10,
                    style.col_lg_7_10,
                    style.col_md_7_10,
                    style.col_sm_1_7,
                    style.illulia__gallery__item
                ])}>
                    <Image
                        classes={[style.illulia__gallery__image]}
                        src={bea}
                        alt="Bee"
                    />
                </div>
                <div className={StyleHelper.concatClass([
                    style.col_xl_10_13,
                    style.col_lg_10_13,
                    style.col_md_10_13,
                    style.col_sm_7_13,
                    style.illulia__gallery__item
                ])}>
                    <img src={flower} alt="Flower" />
                </div>

            </div>
            {/* End Grid */}
            <div className={style.blackBar}>
                <h3 className={style.blackBar__title}>Et sur le plan perso</h3>
                <p className={style.blackBar__text}>Dessin . Ski . Drone . Vidéo . Montagne . Jeux de société &
                    Raclette...</p>
            </div>
        </div>
    )
}

export default About;
