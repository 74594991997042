import RoutesEnum from "../enums/Routes.enum";

export interface Project {
    route: RoutesEnum;
    image: string;
    tags: string[];
    title: string;
    link: RoutesEnum;
}

const projectsOrder = [
    RoutesEnum.Youpss,
    RoutesEnum.Vrais,
    RoutesEnum.Terres,
    RoutesEnum.Labymind,
    RoutesEnum.Epicurien,
    RoutesEnum.BoutDuBout,
];

const projectsData: { [key in RoutesEnum]?: Project } = {
    [RoutesEnum.Youpss]: {
        route: RoutesEnum.Youpss,
        image: require('../assets/alaune/youpss.jpg'),
        tags: ['tags.branding', 'tags.website'],
        title: 'projects.youpss.title',
        link: RoutesEnum.Youpss
    },
    [RoutesEnum.Vrais]: {
        route: RoutesEnum.Vrais,
        image: require('../assets/alaune/vrais-savent.jpg'),
        tags: ['tags.website'],
        title: 'projects.vrais.title',
        link: RoutesEnum.Vrais
    },
    [RoutesEnum.Terres]: {
        route: RoutesEnum.Terres,
        image: require('../assets/alaune/5terres.jpg'),
        tags: ['tags.website'],
        title: 'projects.terres.title',
        link: RoutesEnum.Terres
    },
    [RoutesEnum.Labymind]: {
        route: RoutesEnum.Labymind,
        image: require('../assets/alaune/labymind.jpg'),
        tags: ['tags.branding'],
        title: 'projects.labymind.title',
        link: RoutesEnum.Labymind
    },
    [RoutesEnum.Epicurien]: {
        route: RoutesEnum.Epicurien,
        image: require('../assets/alaune/e-picurien.jpg'),
        tags: ['tags.branding', 'tags.website'],
        title: 'projects.epicurien.title',
        link: RoutesEnum.Epicurien
    },
    [RoutesEnum.BoutDuBout]: {
        route: RoutesEnum.BoutDuBout,
        image: require('../assets/alaune/le-bout-du-bout.jpg'),
        tags: ['tags.branding', 'tags.website'],
        title: 'projects.boutdubout.title',
        link: RoutesEnum.BoutDuBout
    }
};

export const getProjectData = (route: RoutesEnum): Project | null => {
    return projectsData[route] || null;
};

export const getAdjacentProjects = (currentRoute: RoutesEnum): { previous: RoutesEnum | null, next: RoutesEnum | null } => {
    const currentIndex = projectsOrder.indexOf(currentRoute);
    
    if (currentIndex === -1) {
        return { previous: null, next: null };
    }

    const previous = currentIndex > 0 ? projectsOrder[currentIndex - 1] : projectsOrder[projectsOrder.length - 1];
    const next = currentIndex < projectsOrder.length - 1 ? projectsOrder[currentIndex + 1] : projectsOrder[0];

    return { previous, next };
};

const ProjectHelper = {
    getAdjacentProjects,
    getProjectData,
};

export default ProjectHelper; 