import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import style from './PageTransition.module.scss';

interface PageTransitionProps {
    children: React.ReactNode;
}

const PageTransition: React.FC<PageTransitionProps> = ({ children }) => {
    const location = useLocation();
    const [displayLocation, setDisplayLocation] = useState(location);
    const [transitionStage, setTransitionStage] = useState('fadeIn');

    useEffect(() => {
        window.scrollTo({ top: 0 });

        if (location !== displayLocation) {
            setTransitionStage('fadeOut');
        }
    }, [location, displayLocation]);

    const handleAnimationEnd = () => {
        if (transitionStage === 'fadeOut') {
            setDisplayLocation(location);
            setTransitionStage('fadeIn');
        }
    };

    return (
        <div
            className={`${style.content} ${style[transitionStage]}`}
            onAnimationEnd={handleAnimationEnd}
        >
            {displayLocation === location ? children : null}
        </div>
    );
};

export default PageTransition; 