import React, { ReactElement } from 'react';
import ReactDOM from 'react-dom/client';
import style from './index.module.scss';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./views/Home/Home";
import Footer from "./components/Footer/Footer";
import RoutesEnum from "./enums/Routes.enum";
import About from "./views/About/About";
import Projects from "./views/Projects/Projects";
import Epicurien from "./views/Projects/Project/Epicurien/Epicurien";
import Contact from "./views/Contact/Contact";
import Menu from "./components/Menu/Menu";
import Youpss from "./views/Projects/Project/Youpss/Youpss";
import Vrais from "./views/Projects/Project/Vrais/Vrais";
import Terres from "./views/Projects/Project/Terres/Terres";
import Labymind from "./views/Projects/Project/Labymind/Labymind";
import BoutDuBout from "./views/Projects/Project/BoutDuBout/BoutDuBout";
import './i18n';
import Five from "./views/Five/Five";
import OnLocationChanged from "./services/OnLocationChanged";
import MysteriousElixir from "./views/Birthday/MysteriousElixir/MysteriousElixir";
import PageTransition from './components/PageTransition/PageTransition';

interface RouteInterface {
    path: RoutesEnum,
    element: ReactElement,
    index: boolean,
}

const router: RouteInterface[] = [
    {
        path: RoutesEnum.Home,
        element: <Home />,
        index: true
    },
    {
        path: RoutesEnum.About,
        element: <About />,
        index: false
    },
    {
        path: RoutesEnum.Projects,
        element: <Projects />,
        index: false
    },
    {
        path: RoutesEnum.Epicurien,
        element: <Epicurien />,
        index: false
    },
    {
        path: RoutesEnum.BoutDuBout,
        element: <BoutDuBout />,
        index: false
    },
    {
        path: RoutesEnum.Labymind,
        element: <Labymind />,
        index: false
    },
    {
        path: RoutesEnum.Terres,
        element: <Terres />,
        index: false
    },
    {
        path: RoutesEnum.Vrais,
        element: <Vrais />,
        index: false
    },
    {
        path: RoutesEnum.Youpss,
        element: <Youpss />,
        index: false
    },
    {
        path: RoutesEnum.Contact,
        element: <Contact />,
        index: false
    },
    {
        path: RoutesEnum.Five,
        element: <Five />,
        index: false
    }
];

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <React.StrictMode>
        <div className={style.wrapper}>
            <BrowserRouter>
                <OnLocationChanged />
                <PageTransition>
                    <div className={style.wrapper__page}>
                        <Menu />
                        <Routes>
                            {
                                router.map((route: RouteInterface) => {
                                    return <Route path={route.path}
                                        key={route.path}
                                        index={route.index}
                                        element={route.element} />;
                                })
                            }
                        </Routes>
                        <Footer />
                    </div>
                </PageTransition>
            </BrowserRouter>
        </div>
        <BrowserRouter>
            <Routes>
                <Route path={RoutesEnum.MysteriousElixir}
                    key={RoutesEnum.MysteriousElixir}
                    index={false}
                    element={<MysteriousElixir />} />
            </Routes>
        </BrowserRouter>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
