import React from "react";
import Tag from "../../../components/Tag/Tag";
import style from "./ProjectHeader.module.scss";
import RoundLink from "../../../components/Round/Link/RoundLink";
import StyleHelper from "../../../services/Style.helper";
import {useTranslation} from "react-i18next";

interface ProjectHeaderInterface {
    title: string,
    subtitle: string,
    tags: string[],
    link?: string,
    description: string
}

const ProjectHeader = (props: ProjectHeaderInterface) => {
    const {t} = useTranslation();

    return (
        <div className={StyleHelper.concatClass([
            style.grid,
            style.grid__12,
            style.grid__spacing__1
        ])}>
            <div className={StyleHelper.concatClass([
                style.col_xl_1_11,
                style.col_lg_1_11,
                style.col_md_1_13,
                style.col_sm_1_13,
            ])}>
                <div className={StyleHelper.concatClass([
                    style.grid,
                    style.grid__9,
                    style.grid__spacing__1
                ])}>
                    <h1 className={StyleHelper.concatClass([
                        style.col_xl_1_10,
                        style.col_lg_1_10,
                        style.col_md_1_10,
                        style.col_sm_1_10,
                        style.title
                    ])}>{props.title}</h1>
                    <h2 className={StyleHelper.concatClass([
                        style.col_xl_2_11,
                        style.col_lg_2_11,
                        style.col_md_1_10,
                        style.col_sm_1_10,
                        style.subtitle
                    ])}>{props.subtitle}</h2>

                    <div className={StyleHelper.concatClass([
                        style.col_xl_2_11,
                        style.col_lg_2_11,
                        style.col_md_1_10,
                        style.col_sm_1_10,
                    ])}>
                        {
                            props.tags.map((tag: string, index: number) => {
                                return <Tag key={index} title={tag}/>
                            })
                        }
                    </div>
                </div>
            </div>

            <div className={StyleHelper.concatClass([
                style.col_xl_11_13,
                style.col_lg_11_13,
                style.col_md_5_8,
                style.col_sm_5_8,
                style.link
            ])}>
                {
                    props.link ? (
                        <RoundLink text={t('goToSite')}
                                   color={'white'}
                                   fontSize={20}
                                   target={'_blank'}
                                   link={props.link}/>
                    ) : null
                }
            </div>

            <p className={StyleHelper.concatClass([
                style.col_xl_6_12,
                style.col_lg_6_12,
                style.col_md_1_13,
                style.col_sm_1_13,
                style.description
            ])}>
                {props.description}
            </p>
        </div>
    )
}

export default ProjectHeader;
