import React from "react";
import ProjectHeader from "../ProjectHeader";
import image1 from "../../../../assets/img-labymind.png";
import image2 from "../../../../assets/laby.png";
import style from "../ProjectCommon.module.scss";
import StyleHelper from "../../../../services/Style.helper";
import TitleAndDescription from "../../../../components/TitleAndDescription/TitleAndDescription";
import {useTranslation} from "react-i18next";
import Image from "../../../../components/Image/Image";
import ProjectNavigation from "../../../../components/ProjectNavigation/ProjectNavigation";
import RoutesEnum from "../../../../enums/Routes.enum";

const Labymind = () => {

    const {t} = useTranslation();

    return (
        <div>
            <div className={style.text}>
                <ProjectHeader title={t('projects.labymind.title')}
                               subtitle={t('projects.labymind.subtitle')}
                               tags={[t('tags.branding')]}
                               description={t('projects.labymind.description')}
                />
            </div>

            <div className={StyleHelper.concatClass([
                style.grid,
                style.grid__12,
                style.grid__spacing__1
            ])}>
                <Image src={image1} alt={t('projects.labymind.title')} classes={[
                    style.col_xl_2_12,
                    style.col_lg_2_12,
                    style.col_md_2_12,
                    style.col_sm_1_13,
                    style.img,
                ]}/>
                <p className={StyleHelper.concatClass([
                    style.col_xl_3_11,
                    style.col_lg_3_11,
                    style.col_md_2_12,
                    style.col_sm_1_13,
                    style.citation,
                ])}>
                    {t('projects.labymind.citation')}
                </p>
            </div>

            <TitleAndDescription titles={[t('projects.labymind.presentations.one.title')]}
                                 description={t('projects.labymind.presentations.one.description')}/>

            <div className={StyleHelper.concatClass([
                style.grid,
                style.grid__12,
                style.grid__spacing__1
            ])}>
                <Image src={image2} alt={t('projects.labymind.title')} classes={[
                    style.col_xl_2_12,
                    style.col_lg_2_12,
                    style.col_md_2_12,
                    style.col_sm_1_13,
                    style.img,
                ]}/>
            </div>

            <TitleAndDescription titles={[t('projects.labymind.presentations.two.title')]}
                                 description={t('projects.labymind.presentations.two.description')}/>

            <ProjectNavigation currentRoute={RoutesEnum.Labymind} />
        </div>
    )
}

export default Labymind;
