import React from 'react';
import style from './ProjectNavigation.module.scss';
import { useTranslation } from "react-i18next";
import ProjectHelper from "../../services/Project.helper";
import RoutesEnum from "../../enums/Routes.enum";
import StyleHelper from '../../services/Style.helper';
import ProjectCard from '../ProjectCard/ProjectCard';

interface ProjectNavigationProps {
    currentRoute: RoutesEnum;
}

const ProjectNavigation = ({ currentRoute }: ProjectNavigationProps) => {
    const { t } = useTranslation();
    const { previous, next } = ProjectHelper.getAdjacentProjects(currentRoute);

    if (!previous || !next) {
        return null;
    }

    const previousProject = ProjectHelper.getProjectData(previous);
    const nextProject = ProjectHelper.getProjectData(next);

    if (!previousProject || !nextProject) {
        return null;
    }

    return (
        <div className={StyleHelper.concatClass([
            style.navigation,
            style.grid,
            style.grid__12,
            style.grid__spacing__1,
        ])}>
            <div className={StyleHelper.concatClass([
                style.navigation__previous,
                style.col_xl_1_4,
                style.col_lg_1_4,
                style.col_md_1_4,
                style.col_sm_1_13,
            ])}>
                <ProjectCard
                    tags={previousProject.tags.map(tag => t(tag))}
                    title={t(previousProject.title)}
                    image={previousProject.image}
                    link={previousProject.link} />
            </div>

            <div className={StyleHelper.concatClass([
                style.navigation__center,
                style.col_xl_5_9,
                style.col_lg_5_9,
                style.col_md_5_9,
                style.col_sm_1_13,
            ])}>
                <h2 className={style.navigation__title}>{t('project.next')}</h2>
                <p className={style.navigation__subtitle}>{t('project.choose')}</p>
            </div>

            <div className={StyleHelper.concatClass([
                style.navigation__next,
                style.col_xl_10_13,
                style.col_lg_10_13,
                style.col_md_10_13,
                style.col_sm_1_13,
            ])}>
                <ProjectCard
                    tags={nextProject.tags.map(tag => t(tag))}
                    title={t(nextProject.title)}
                    image={nextProject.image}
                    link={nextProject.link} />
            </div>
        </div>
    );
};

export default ProjectNavigation; 