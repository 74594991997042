import {useLocation} from "react-router-dom";
import {useEffect} from "react";

const OnLocationChanged = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        const images: HTMLCollectionOf<HTMLElementTagNameMap["img"]> = document.getElementsByTagName("img");
        Array.from(images).forEach((img: HTMLImageElement) => {
            img.oncontextmenu = () => false;
            img.draggable = false;
        });
    }, [pathname]);

    return null;
}

export default OnLocationChanged;
