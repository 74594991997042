import React from "react";
import ProjectHeader from "../ProjectHeader";
import image1 from "../../../../assets/epicurien/epitop.svg";
import image2 from "../../../../assets/epicurien/logo.svg";
import image3 from "../../../../assets/epicurien/colors.svg";
import image4 from "../../../../assets/epicurien/cards.svg";
import image5 from "../../../../assets/epicurien/devices.svg";
import image6 from "../../../../assets/epicurien/grid.svg";
import image7 from "../../../../assets/epicurien/left.svg";
import image8 from "../../../../assets/epicurien/right.svg";
import style from "../ProjectCommon.module.scss";
import StyleHelper from "../../../../services/Style.helper";
import TitleAndDescription from "../../../../components/TitleAndDescription/TitleAndDescription";
import {useTranslation} from "react-i18next";
import Image from "../../../../components/Image/Image";
import ProjectNavigation from "../../../../components/ProjectNavigation/ProjectNavigation";
import RoutesEnum from "../../../../enums/Routes.enum";

const Epicurien = () => {

    const {t} = useTranslation();

    return (
        <div>
            <div className={style.text}>
                <ProjectHeader title={t('projects.epicurien.title')}
                               subtitle={t('projects.epicurien.subtitle')}
                               tags={[t('tags.branding'), t('tags.website')]}
                               description={t('projects.epicurien.description')}
                               link={'https://www.e-picurien.com/'}
                />
            </div>

            <div className={StyleHelper.concatClass([
                style.grid,
                style.grid__12,
                style.grid__spacing__1
            ])}>
                <Image src={image1} alt={t('projects.epicurien.title')} classes={[
                    style.col_xl_2_12,
                    style.col_lg_2_12,
                    style.col_md_2_12,
                    style.col_sm_1_13,
                    style.img,
                ]}/>
                <p className={StyleHelper.concatClass([
                    style.col_xl_3_11,
                    style.col_lg_3_11,
                    style.col_md_2_12,
                    style.col_sm_1_13,
                    style.citation,
                ])}>
                    {t('projects.epicurien.citation')}
                </p>
                <Image src={image2} alt={t('projects.epicurien.title')} withEffect={false} classes={[
                    style.col_xl_2_12,
                    style.col_lg_2_12,
                    style.col_md_2_12,
                    style.col_sm_1_13,
                    style.img,
                ]}/>
                <Image src={image3} alt={t('projects.epicurien.title')} withEffect={false} classes={[
                    style.col_xl_2_12,
                    style.col_lg_2_12,
                    style.col_md_2_12,
                    style.col_sm_1_13,
                    style.img,
                ]}/>
            </div>

            <TitleAndDescription titles={[t('projects.epicurien.presentations.one.title')]}
                                 description={t('projects.epicurien.presentations.one.description')}/>

            <div className={StyleHelper.concatClass([
                style.grid,
                style.grid__12,
                style.grid__spacing__1
            ])}>
                <Image src={image4} alt={t('projects.epicurien.title')} classes={[
                    style.col_xl_1_13,
                    style.col_lg_1_13,
                    style.col_md_1_13,
                    style.col_sm_1_13,
                    style.img,
                ]}/>
            </div>

            <TitleAndDescription titles={[t('projects.epicurien.presentations.two.title')]}
                                 description={t('projects.epicurien.presentations.two.description')}/>

            <div className={StyleHelper.concatClass([
                style.grid,
                style.grid__12,
                style.grid__spacing__1
            ])}>
                <Image src={image5} alt={t('projects.epicurien.title')} classes={[
                    style.col_xl_2_12,
                    style.col_lg_2_12,
                    style.col_md_2_12,
                    style.col_sm_1_13,
                    style.img,
                ]}/>
            </div>

            <TitleAndDescription titles={[t('projects.epicurien.presentations.three.title')]}
                                 description={t('projects.epicurien.presentations.three.description')}/>

            <div className={StyleHelper.concatClass([
                style.grid,
                style.grid__12,
                style.grid__spacing__1
            ])}>
                <Image src={image6} alt={t('projects.epicurien.title')} classes={[
                    style.col_xl_2_12,
                    style.col_lg_2_12,
                    style.col_md_2_12,
                    style.col_sm_1_13,
                    style.img,
                    style.img__grid
                ]}/>
                <Image src={image7} alt={t('projects.epicurien.title')} classes={[
                    style.col_xl_2_7,
                    style.col_lg_2_7,
                    style.col_md_2_7,
                    style.col_sm_1_13,
                    style.img,
                    style.img__grid
                ]}/>
                <Image src={image8} alt={t('projects.epicurien.title')} classes={[
                    style.col_xl_7_12,
                    style.col_lg_7_12,
                    style.col_md_7_12,
                    style.col_sm_1_13,
                    style.img,
                    style.img__grid
                ]}/>
            </div>

            <ProjectNavigation currentRoute={RoutesEnum.Epicurien} />
        </div>
    )
}

export default Epicurien;
